// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "@ngxpert/hot-toast/src/styles/styles.scss";
// Plus imports for other components in your app.
//@use "@fontsource/lato/400.css";
//@use "@fontsource/lato/700.css";
@use "root";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

.mat-mdc-tooltip,
.mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-line-height: normal;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
}

* {
  box-sizing: border-box;
}

html,
body,
app-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: var(--pws-font);
}

body {
  background-color: var(--pws-body-bg);
  color: var(--pws-body-color);
}

.as-split-gutter {
  background-color: var(--pws-bg-200) !important;
}

s {
  flex-grow: 1;
}

.mat-mdc-dialog-container {
  display: flex !important;

  > * {
    flex-grow: 1;
  }
}

.d-flex {
  display: flex;
}

svg-icon > svg {
  fill: currentColor;
}

.w-100 {
  width: 100%;
}

.logo-title[fill] {
  fill: var(--pws-body-color);
}

.logo-subtitle[fill] {
  fill: var(--pws-text-muted);
}

.logo-subtitle[stroke] {
  stroke: var(--pws-text-muted);
}

.logo-icon[fill] {
  fill: var(--pws-color-primary);
}

.portugol-renderer-dialog .mat-mdc-dialog-content {
  max-height: unset;
}

markdown {
  padding: 0 1rem;

  h1 {
    margin: 1rem 0;
    font-size: 15pt;
    padding: 1rem;
    background-color: var(--pws-bg-100);
    border-left: 0.25rem solid var(--pws-color-secondary);
  }

  a, a:visited {
    color: var(--pws-color-secondary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;
      list-style-type: disc;
    }
  }

  code {
    display: inline-block;
    padding: 4px 6px;
    font-family: monospace;
    background-color: var(--pws-bg-100);
    border-radius: 0.25rem;
  }

  .code {
    display: block;
    padding: 1rem;
    overflow: auto;
    font-family: monospace;
    background-color: var(--pws-bg-100);

    .code-keyword {
      color: #569cd6;
    }

    .code-const-name {
      color: #3dc9b0;
    }

    .code-func-name {
      color: #f5d7a9;
    }

    .code-keyword-brackets {
      color: #da70d6;
    }

    .code-param-name {
      color: #d4d4d4;
    }

    .code-value {
      color: #b5cea8;
    }
  }
}

[data-theme="light"] {
  markdown .code {
    .code-keyword {
      color: #0000ff;
    }

    .code-const-name {
      color: #008080;
    }

    .code-func-name {
      color: #ad7f00;
    }

    .code-keyword-brackets {
      color: #319331;
    }

    .code-param-name {
      color: #000;
    }

    .code-value {
      color: #098658;
    }
  }
}
